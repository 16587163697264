.navbarBg {
  background-color: #071A2B !important;
}

.active {
  color: #071A2B !important;
  background-color: #00A3E0; /* Set your desired color */
}

.logoutButton {
    color: rgb(255, 255, 255) !important;
    background-color: #00A3E0;
    border-color: #00A3E0 !important;
  }
  
  .logoutButton:hover {
    color: #ffffff !important;
    background-color: #071A2B;
    border-color: #071A2B !important;
  }

  .navigationLogoutButton {
    color: rgb(255, 255, 255) !important;
    background-color: #00A3E0;
    border-color: #00A3E0 !important;
  }
  
  .navigationLogoutButton:hover {
    color: #00A3E0 !important;
    background-color: #fff;
    border-color: #00A3E0 !important;
  }

  .dashboardLogoutButton {
    color: rgb(255, 255, 255) !important;
    background-color: #00A3E0;
    border-color: #00A3E0 !important;
  }
  
  .dashboardLogoutButton:hover {
    color: #ffffff !important;
    background-color: #071A2B;
    border-color: #ffffff !important;
  }
  
  .resetPassCloseButton {
    color: #ffffff !important;
    background-color: #071A2B;
    border-color: #071A2B !important;
  }

  .resetPassCloseButton:hover {
    color: #071A2B !important;
    background-color: #fff;
    border-color: #071A2B !important;
  }

  .resetPassButton {
    color: #ffffff !important;
    background-color: #00A3E0;
    border-color: #00A3E0 !important;
  }

  .resetPassButton:hover {
    color: #00A3E0 !important;
    background-color: #fff;
    border-color: #00A3E0 !important;
  }

  @keyframes blink {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: #00ff00;
    }
    100% {
      border-color: transparent;
    }
  }
  
  .blink-green-ring {
    border: 3px solid transparent;
    animation: blink 1s infinite;
  }